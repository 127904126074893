<template>
  <div class="edit-table">
    <vxe-table show-overflow="" ref="fineTable" :data="subjectList">
      <vxe-table-column min-width="100" field="triggerAction" title="扫码动作"></vxe-table-column>
      <vxe-table-column min-width="100" field="amount" title="奖励金额"></vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>

import request from '@/found/utils/request';

export default {
  name: 'BudgetSubject',
  components: {},
  props: {
    value: Array,
    getValue: Function,
  },
  watch: {
    value() {
      this.subjectList = this.value.map((a) => ({
        ...a,
      }));
    },
  },
  data() {
    return {
      subjectList: [], // 当前关联费用科目数据
    };
  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;
}
  .text-red {
    color: #f56c6c !important;
  }
</style>
