import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ScanList from './components/scanList.vue';

formCreate.component('ScanList', ScanList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'dealer_fee_bill_form',
      formParentCode: 'CRM20211030000002754',
    };
  },

  methods: {

    // 完成渲染
    formComplete() {
      if (this.formConfig.code === 'view') {
        request.get('/cps-mobile/h5/cost/dealer/findById', {
          id: this.formConfig.row.id,
        }).then((res) => {
          if (res.success) {
            this.setValue({
              ...res.result,
            });
          }
        });
      }
    },

    // 提交
    submit() {
      this.$emit('onClose');
    },
  },
};
